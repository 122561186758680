import axios from "axios";
import { getAccessToken } from "src/utils/token";
import toast from "react-hot-toast";
import { botEngineConfig } from "src/config";
import { CreateBotTheme } from "src/types/botTheme";

class BotThemeApi{

  async fetchAllBotThemes(){
    try {
      const {baseUrl} = botEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${baseUrl}/bot-themes`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchBotTheme(id: string){
    try {
      const {baseUrl} = botEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/bot-themes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    return e?.response?.data?.message || "Something went wrong";
    }
  }

  async createBotTheme(body: CreateBotTheme){
    try {
      const {baseUrl} = botEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/bot-themes`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    return e?.response?.data?.message || "Something went wrong";
    }
  }

  async deleteBotTheme(id: string){
    try {
      const {baseUrl} = botEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.delete(`${baseUrl}/bot-themes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    return e?.response?.data?.message || "Something went wrong";
    }
  }

  async updateBotTheme(id: string, body: CreateBotTheme){
    try {
      const {baseUrl} = botEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.patch(`${baseUrl}/bot-themes/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    return e?.response?.data?.message || "Something went wrong";
    }
  }

}

export const botThemeApi = new BotThemeApi();