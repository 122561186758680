import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import { authApi } from '../__Api__/authApi';
import axios from 'axios';
import wait from 'src/utils/wait';
import { useDispatch } from 'src/store';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  error: Error;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  logoutSSO: () => Promise<void>;
  register: (email: string, firstname: string, lastname: string, password: string) => Promise<void>;
  loginSSO: (data: any, provider: string) => Promise<void>;
  refreshToken: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};


type LoginFailedAction = {
    type: 'LOGIN_FAILED';
    payload: {
      error: any;
    };
  };


type RefreshTokenAction = {
  type: "REFRESH_TOKEN";
}

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | LoginFailedAction
  | RefreshTokenAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      error: null,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
    LOGIN_FAILED: (state: State, action: LoginFailedAction ): State => ({ 
      ...state,
      error: action.payload.error,
    }),

};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  logoutSSO: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginSSO: () => Promise.resolve(),
  refreshToken: ()=> Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  // const accessToken = window.localStorage.getItem('accessToken');
  // useEffect(() => {
    
  //   if (accessToken) {
  //     balanceServiceAPI.fetchAllExchangeBalances(accessToken)
  //   }
  // }, [accessToken])

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const sso = window.localStorage.getItem('sso');
        const userEmail = window.localStorage.getItem('userEmail');        
        if (accessToken) {
          setSession(accessToken);
          let user;
          if(sso) {
            user = userEmail;
          } else {
            user = await authApi.resolveUser(accessToken);
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    
    // try{

      
    //   let formData="grant_type=password&client_id=apexe3-ai-login&username="+email+"&password="+password;
    //   let res = await axios.post('/auth/realms/ApexE3/protocol/openid-connect/token',formData,{
  
    //     headers: {
    //       'content-type': 'application/x-www-form-urlencoded'
    //     }
    //   });
  
    //   console.log("RESULT FROM LOGIN: ", res);

    //   if(res!=null && res.data!=null && res.data.access_token!=null){

    //     let user = await authApi.resolveUser(res.data.access_token);
        
    //     localStorage.setItem('accessToken', res.data.access_token);
    //     setSession(res.data.access_token);

    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         user
    //       }
    //     });

    //   }else{}

    // }catch(e){
    //   new Error('Invalid authorization token');
    //   console.log('error',email,password, e);
    // }

    try {
        // console.log("making login api call");
        const data:any = await authApi.login({ email, password });
        const date = new Date().getTime();
        const accessToken = data.access_token;
        const refreshToken = {token: data.refresh_token, expiresIn: ((date * 0.001) + data.refresh_expires_in)};
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
        localStorage.setItem('userEmail', JSON.stringify(email));
        localStorage.setItem('sso', JSON.stringify(false));
        setSession(accessToken);
        
        let user = await authApi.resolveUser(accessToken);
        
        localStorage.setItem('userName', JSON.stringify(user?.name));

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            }
        });
    } catch (error) {
        console.log( error, 'could not set user session..' );
        
        dispatch({
            type: 'LOGIN_FAILED',
            payload: {
                error,
            }
        });
  }

    // const accessToken = await authApi.login({ email, password });
    // const user = await authApi.me(accessToken);

    // ///Put in here
    // localStorage.setItem('accessToken', accessToken);

    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user
    //   }
    // });
  };

  const logout = async (): Promise<void> => {
      await authApi.logout();
      reduxDispatch({ type: 'LOGOUT' });
      dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('sso');
      window.location.replace('/');
  };

  const register = async (
    email: string,
    firstname: string,
    lastname: string, 
    password: string
  ): Promise<void> => {
    await authApi.registerKC({ email, firstname, lastname, password });
    await wait(1000);

    const data:any = await authApi.login({ email, password });
    const date = new Date().getTime();
    const accessToken = data.access_token;
    const refreshToken = {token: data.refresh_token, expiresIn: ((date * 0.001) + data.refresh_expires_in)};
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
    localStorage.setItem('userEmail', JSON.stringify(email));
    localStorage.setItem('sso', JSON.stringify(false));
    setSession(accessToken);
    let user = await authApi.resolveUser(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const loginSSO = async (data: any, provider: string): Promise<void> => {
    try {
        const response:any = await authApi.loginSSO({ data, provider });
        const accessToken = response.access_token;        
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userEmail', JSON.stringify(response?.user?.email));
        localStorage.setItem('userName', JSON.stringify(response?.user?.username));
        // localStorage.setItem('adminAccessToken', JSON.stringify(adminRes?.access_token));
        localStorage.setItem('sso', JSON.stringify(true));
        setSession(accessToken);

        dispatch({
          type: 'REGISTER',
          payload: {
            user: response?.user,
          }
        });
        dispatch({
            type: 'LOGIN',
            payload: {
              user: response?.user,
            }
        });
    } catch (error) {
        console.log( error, 'could not set user session..' );
        
        dispatch({
            type: 'LOGIN_FAILED',
            payload: {
                error,
            }
        });
  }
}

const logoutSSO = async (): Promise<void> => {
  reduxDispatch({ type: 'LOGOUT' });
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('sso');
  localStorage.removeItem('userName');
  window.location.replace('/');
};


  const refreshToken = async (): Promise<void> => {
   
    try {
      const data:any = await authApi.refreshToken();
      const date = new Date().getTime();
      const accessToken = data.access_token;
      const refreshToken = {token: data.refresh_token, expiresIn: ((date * 0.001) + data.refresh_expires_in)};
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
      setSession(accessToken);
      dispatch({
        type: 'REFRESH_TOKEN'
      });

    } catch (error) {
        console.log( error, 'could not set user session..' );
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userEmail');
        reduxDispatch({ type: 'LOGOUT' });
        dispatch({ type: 'LOGOUT' });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        logoutSSO,
        register,
        loginSSO,
        refreshToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
